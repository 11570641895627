@import "variables";

html,
body {
  height: 100%;
  overflow: hidden;
}

.fullHeight {
  height: 100vh;
}

.central-height {
  height: 90vh;
  overflow-y: auto;
}

.board-height {
  height: 92vh;
}

.side-bar {
  height: 92vh;
}

.top-bar {
  height: 8vh;
}

.logoDiv {
  width: 5em;
}

.bg_user {
  background: url("/assets/images/main-background.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.image_user {
  width: 20em;
  object-fit: cover;
}

.team {
  width: 5em;
  height: 5em;
  object-fit: cover;
}

.teamNavAtTab {
  width: 2em;
  height: 2em;
  object-fit: cover;
}

.teamNav {
  width: 3em;
  height: 3em;
  object-fit: cover;
}

.boardTeamPositionCenter {
  position: absolute;
  top: 10%;
  left: 20%;
  width: 3em;
  height: 3em;
  z-index: 1000;
}

.boardTeamPositionLarge1 {
  position: absolute;
  top: 50%;
  left: -11%;
  width: 3em;
  height: 3em;
  z-index: 101;
}

.boardTeamPositionLarge2 {
  position: absolute;
  top: 50%;
  left: 11%;
  width: 3em;
  height: 3em;
  z-index: 102;
}

.boardTeamPositionLarge3 {
  position: absolute;
  top: 50%;
  left: 30%;
  width: 3em;
  height: 3em;
  z-index: 103;
}

.boardTeamPositionLarge4 {
  position: absolute;
  top: -5%;
  left: -5%;
  width: 3em;
  height: 3em;
  z-index: 104;
}

.boardTeamPositionLarge5 {
  position: absolute;
  top: -5%;
  left: 16%;
  width: 3em;
  height: 3em;
  z-index: 105;
}

.boardTeamPositionLarge6 {
  position: absolute;
  top: -5%;
  left: 32%;
  width: 3em;
  height: 3em;
  z-index: 106;
}

.boardTeamPositionSmall1 {
  position: absolute;
  top: 50%;
  left: -10%;
  width: 2em;
  height: 2em;
  z-index: 101;
}

.boardTeamPositionSmall7 {
  position: absolute;
  top: 55%;
  left: 2%;
  width: 2em;
  height: 2em;
  z-index: 102;
}

.boardTeamPositionSmall3 {
  position: absolute;
  top: 53%;
  left: 15%;
  width: 2em;
  height: 2em;
  z-index: 103;

}

.boardTeamPositionSmall4 {
  position: absolute;
  top: 50%;
  left: 27%;
  width: 2em;
  height: 2em;
  z-index: 104;
}

.boardTeamPositionSmall5 {
  position: absolute;
  top: 52%;
  left: 40%;
  width: 2em;
  height: 2em;
  z-index: 105;
}

.boardTeamPositionSmall6 {
  position: absolute;
  top: 51%;
  left: 55%;
  width: 2em;
  height: 2em;
  z-index: 106;
}

.boardTeamPositionSmall2 {
  position: absolute;
  top: 1%;
  left: -9%;
  width: 2em;
  height: 2em;
  z-index: 107;
}

.boardTeamPositionSmall8 {
  position: absolute;
  top: 3%;
  left: 1%;
  width: 2em;
  height: 2em;
  z-index: 108;
}

.boardTeamPositionSmall10 {
  position: absolute;
  top: -2%;
  left: 17%;
  width: 2em;
  height: 2em;
  z-index: 109;
}

.boardTeamPositionSmall9 {
  position: absolute;
  top: -5%;
  left: 30%;
  width: 2em;
  height: 2em;
  z-index: 110;
}

.boardTeamPositionSmall11 {
  position: absolute;
  top: 18%;
  left: 55%;
  width: 2em;
  height: 2em;
  z-index: 111;
}

.boardIcon {
  width: 4.5em;
  height: 4.5em;
  object-fit: cover;
  z-index: 10;
}

.boardColumn {
  position: relative;
  width: 5.5em;
}

.boardColumnDouble {
  position: relative;
  width: 11em;
  height: 4.5em;
}

.boardColumnDoubleLogo {
  position: relative;
  width: 11em;
}

.boardDynargieLogo {
  width: 11em;
  height: 11em;
  object-fit: cover;
  z-index: 10;
}

.boardStart {
  width: 10em;
  height: 100%;
  object-fit: cover;
  z-index: 10;
}

.paddingRight0 {
  padding-right: 0;
}

.paddingLeft1 {
  padding-left: 10% !important;
}

.main-container {
  width: 100%;
}

.border-bottom {
  border-bottom: 2px solid #ffffff !important
}

.bg-secondary {
  background-color: $orange !important;
}

.bg-primary {
  background-color: $orange-dark!important;
}

.text-primary {
  color: #ffffff!important;
}

a.text-primary:hover {
  color: $orange !important;
}

a.text-primary:focus {
  color: $orange !important;
  font-weight: bold;
}

a.text-secondary:focus {
  color: $orange !important;
  font-weight: bold;
}

.text-secondary {
  color: #ffffff !important;
}

a.text-secondary:hover {
  color: #000000 !important
}

/* change the color of active or hovered links */
.nav-item:hover .nav-link {
  background-color: #FFFFFF; /* add background-color to active links */
}

.active {
  color: $orange  !important;
  background-color: #FFFFFF;
  font-weight: bold;
}

.btn-primary {
  color: #FFFFFF !important;
  background-color: $orange-dark !important;
  border-color: $orange-dark !important;
}

.table-primary {
  color: white !important;
  background-color: $orange !important;
}

.page-item.active .page-link {
  z-index: 1;
  color: white;
  background-color: $orange;
  border-color: $orange;
}

.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: $orange;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.sameLine-buttons {
  display: inline-block;
}

.cards .card-container {
  width: 150px;
  display: block;
}
.gameCard {
  height: 200px;
  border-radius: 10px;
}

.card-red {
  background-color: $red;
}

.card-green {
  background-color: $green;
}

.card-orange {
  background-color: $orange;
}

.gameCard .card-header {
  padding: 0;
  margin: 0.3rem;
  background-color: white;
  border-radius: 10px;
  height: 40px;
}

.gameCard .card-body {
  padding: 0;
  margin: 0.3rem;
  background-color: white;
  border-radius: 10px;
  flex: none;
  height: 90px;
  text-align: center;
}

.gameCard.no-header .card-body {
  height: 140px;
}

.cards .card-subtitle {
  text-align: center;
  margin-top: 10px;
  font-size: 130%;
  color: grey;
  font-weight: bold;
  padding: 0;
}

.cards a:hover {
  text-decoration: none;
}

.cards .card .plusIcon {
  color: grey;
  width: 35px;
  height: 35px;
  margin-top: 25px;
}

.gameCard.no-header .plusIcon {
  margin-top: 50px;
}

.nav-tabs .nav-link.active.red {
  background-color: $red;
}

.nav-tabs .nav-link.active.green {
  background-color: $green;
}

.nav-tabs .nav-link.active.orange {
  background-color: $orange;
}

.nav-tabs {
  .nav-item {
    a {
      color: grey;
      font-weight: bold;
      font-size: x-large;
    }
  }

  .nav-link {
    border-radius: 0;
    border: 1px solid $orange;
  }

  .nav-link:hover {
    border-color: $orange;
  }

  .nav-link.active {
    color: #fff !important;
    border-color: $orange !important;
  }
}

.margin-0 {
  margin: 0;
}

.margin-right-0 {
  margin-left: 0;
}

.main-container {
  margin-right: 0;
}

.contentContainer {
  margin-left: 3%;
  margin-right: 3%;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.cardListTitle {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.cardListSubtitle {
  margin-top: 1rem;
}

input[type=text] {
  border-radius: 0;
  border: 2px $input-border-color;
  border-style: none none solid none;
}

textarea {
  border-radius: 0 !important;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom: 2px $input-border-color solid !important;
}

select {
  background-color: transparent;
  border-bottom: 2px solid $input-border-color;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 5px;
  color: $card-form-placeholder-color;
  font-weight: bold;
}

.hidden {
  display: none;
}

.text-in-card-lists {
  text-overflow: ellipsis;
  overflow:hidden;
  white-space:nowrap;
  max-width: 100%;
}

.text-green {
  color: green;
}

.text-red {
  color: red;
}

.text-in-tables {
  text-overflow: ellipsis;
  overflow:hidden;
  white-space:nowrap;
  max-width: 100%;
}

.card-text-question-overflow {
  width: 100%;
  height: 7em;
  overflow-y: auto;
}

.card-text-questionGreen-overflow {
  width: 100%;
  height: 15em;
  overflow-y: auto;
}

.card-text-questionOrange-overflow {
  width: 100%;
  height: 3em;
  overflow-y: auto;
}

.card-text-answerRed-overflow {
  width: 100%;
  height: 3em;
  overflow-y: auto;
}

.card-text-answerOrange {
  height: 15%;
  overflow-y: auto;
}

.orangeCardGray {
  background-color: #999999;
}

.diceGrayed {
  opacity: 0.4;
  filter: alpha(opacity=40);
}

.diceRolled {

}

.inDeck {
  font-weight: bold;
}

.cardCompact-red {
  border: 0.3em solid $red;
  padding: 0.3em;
}

.cardCompact-green {
  border: 0.3em solid $green;
  padding: 0.3em;
}

.cardCompact-orange {
  border: 0.3em solid $orange;
  padding: 0.3em;
}

.textInTable {
  color: black;
  min-height: 8em;
}

.text-quite-close {
  color: darkorange;
}

.judgementFooterHeight {
  height: 4em;
}

.smallLoader {
  border: 0.3em solid #f3f3f3;
  border-radius: 50%;
  border-top: 0.3em solid $orange-dark;
  width: 2.2em;
  height: 2.2em;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.smallLoaderOnCards {
  border: 0.5em solid #f3f3f3;
  border-radius: 50%;
  border-top: 0.5em solid orange;
  width: 2em;
  height: 2em;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

#drawNextCardButton{
  font-size: large;
}